import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <p>The technology was jointly developed by</p>
 <a href="https://microturbines.it/en/"><img src="./MicroTurbines-Logo.png" alt="ANRO" height="50" /></a>
 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 <a href="https://www.medius.si/"><img src="./Medius-Logo.svg" alt="ANRO" height="50" /></a>
    <p>{`ARNO will redefine water management.`}</p>
    <h2>{`Acknowledgements`}</h2>
    <img src="./eu_flag.svg" alt="ANRO" width="60" height="40" /> 
&nbsp;&nbsp;This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No. 864266.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      