import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Our technology will be instrumental to provide control and monitoring on isolated and remote sites, contributing to a better management of water infrastructures. `}</p>
    <iframe align="top" height="350" width="600" src="https://www.youtube.com/embed/QDEsdNe_fHc">
    </iframe>
    <img style={{
      "maxWidth": "30em",
      "marginLeft": "1em"
    }} src="./DigiFed-ARNO-SAS.drawio.png" alt="ANRO" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      