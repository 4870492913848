import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img src="./ARNO.svg" alt="ARNO" style={{
      "maxWidth": "30em"
    }} />
    <p>{`ARNO is a complete off-grid water management system that enables continuous smart remote monitoring of water service networks.
Our solution helps increase pipeline and asset lifetime, reduces maintenance, all leading to a better service.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      